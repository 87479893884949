<template>
  <div class="d-flex justify-end align-center">
    <v-btn
      v-if="task.attributes.status !== 'rejected'"
      depressed
      small
      :color="actionButton.color"
      :loading="isLoading"
      class="text-capitalize mr-1"
      :disabled="actionButton.disabled"
      @click="changeStatus(actionButton.value)"
    >
      {{ actionButton.label }}
    </v-btn>

    <v-btn
      v-if="task.attributes.status !== 'delivered'"
      depressed
      outlined
      small
      :color="rejectButton.color"
      :loading="isLoading"
      class="text-capitalize mr-1"
      @click="changeStatus(rejectButton.value)"
    >
      {{ rejectButton.label }}
    </v-btn>

    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title>
          {{ $t('alerts.reasonForRejection') }}
        </v-card-title>

        <v-card-text class="mt-3">
          <base-textarea-input
            v-model="reason"
            :placeholder="$t('formText.addInstructionsPlaceholder')"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" text @click="dialog = false">
            {{ $t('actions.cancel') }}
          </v-btn>
          <v-btn color="primary" depressed @click="rejectTask">
            {{ $t('actions.reject') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
export default {
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      dialog: false,
      reason: ''
      // item: {}
    }
  },
  computed: {
    ...mapGetters('auth', ['isAdmin']),
    actionButton() {
      const { status } = this.task.attributes
      const { inputItems, outputItems } = this.task.attributes
      switch (status) {
        case 'pending':
          return {
            label: this.$t('workOrder.status.start'),
            value: 'started',
            color: 'primary',
            disabled: inputItems.length && !outputItems.length ? true : false
          }
        case 'started':
          return {
            label: this.$t('workOrder.status.deliver'),
            value: inputItems.length ? 'to-be-deliver' : 'delivered',
            color: 'warning',
            disabled: false
          }
        case 'delivered':
          return {
            label: this.$t('workOrder.status.delivered'),
            value: null,
            color: null,
            disabled: true
          }
        default:
          console.log('There was an error related to task status')
          return null
      }
    },
    rejectButton() {
      const { status } = this.task.attributes
      switch (status) {
        case 'pending':
          return {
            label: this.$t('workOrder.status.reject'),
            value: 'rejected',
            color: 'light',
            disabled: false
          }
        case 'started':
          return {
            label: this.$t('workOrder.status.reject'),
            value: 'rejected',
            color: 'light',
            disabled: false
          }
        case 'rejected':
          return {
            label: this.$t('workOrder.status.rejected'),
            value: null,
            color: 'red lighten-1',
            disabled: true
          }
        default:
          console.log('There was an error related to task status')
          return null
      }
    }
  },

  methods: {
    ...mapActions({
      addNotification: 'notification/add'
    }),
    async changeStatus(status) {
      if (status === 'to-be-deliver') return this.$emit('toggle-row')

      const { inputItems } = this.task.attributes
      if (status === 'rejected') this.dialog = true
      else if (status === 'delivered' && inputItems.length) {
        const itemIdIsValid = await this.checkOutputItemId()
        if (itemIdIsValid) {
          this.updateTaskStatus('delivered')
          this.$emit('toggle-row')
        } else {
          const message = this.$t('alerts.outputNotReady')
          this.dispatchError(message, 'warning')
          this.isLoading = false
          return
        }
      } else if (status === 'started' && inputItems.length) {
        const stock = await this.checkStockForInputItems()
        const itemIdIsValid = await this.checkOutputItemId()
        if (!stock) {
          const message = this.$t('alerts.stockNotEnough')
          this.dispatchError(message, 'error')
          this.isLoading = false
          return
        } else if (itemIdIsValid) this.updateTaskStatus(status)
        else {
          const message = this.$t('alerts.outputNotReady')
          this.dispatchError(message, 'warning')
          this.isLoading = false
          return
        }
      } else this.updateTaskStatus(status)
    },

    async updateTaskStatus(status, statusReason = null) {
      this.isLoading = true
      Object.assign(this.task.attributes, { status, statusReason })
      try {
        await this.updateTaskState(this.task)
        this.addNotification({
          status: '200',
          color: 'success',
          time: 2000,
          messages: { title: 'Successfully updated' }
        })
        this.$emit('click')
        this.isLoading = false
      } catch (error) {
        if (!error.response) return
        this.isLoading = false
        const {
          status,
          data: { errors }
        } = error.response
        return this.addNotification({
          status,
          messages: errors,
          color: 'error',
          time: 10000
        })
      }
    },
    rejectTask() {
      this.dialog = false
      this.updateTaskStatus('rejected', this.reason)
    },
    async checkOutputItemId() {
      const { outputItems } = this.task.attributes
      const taskItemId =
        outputItems.length && outputItems[outputItems.length - 1].data.id
      const outputItem = await this.fetchOutputItem({
        taskId: this.task.id,
        taskItemId
      })
      return outputItem.attributes.itemId ? true : false
    },

    async checkStockForInputItems() {
      const { inputItems } = this.task.attributes
      const { itemId, units } = inputItems[0].data.attributes

      const item = await this.fetchItem(itemId)
      const { itemUnitsInStockCount } = item.data.attributes
      return itemUnitsInStockCount >= units ? true : false
    },
    dispatchError(message, type) {
      this.$emit('error', {
        id: this.task.id,
        message,
        type
      })
    },
    ...mapActions({
      fetchOutputItem: 'outputItem/fetch',
      fetchItem: 'inventoryItem/fetch',
      updateTaskState: 'task/state'
    })
  }
}
</script>

<style lang="scss" scoped>
.max-width {
  max-width: 150px;
}
</style>
