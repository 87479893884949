<template>
  <v-data-table :headers="headers" :items="items" :items-per-page="-1">
    <template v-slot:item.attributes.name="{ item }">
      <div class="py-5">{{ item.attributes.name | titleCase }}</div>
    </template>

    <template v-slot:item.status="{ item }">
      <v-chip small light text-color="grey darken-2">
        {{ item.attributes.status | titleCase }}</v-chip
      >
    </template>
    <template v-slot:item.supplier>
      Tonewoods S.L
    </template>

    <template v-slot:item.attributes.pendingItemUnitsCount="{ item }">
      {{ item.attributes.pendingItemUnitsCount }} ({{
        item.attributes.customAttributes.packQuantity || '1'
      }})
    </template>

    <template v-slot:item.totalVolume="{ item }">
      {{
        item.attributes.volume *
          item.attributes.pendingItemUnitsCount *
          (item.attributes.customAttributes.packQuantity || 1)
      }}
      {{ item.attributes.volume && item.attributes.volumeUnit }}
    </template>

    <template v-slot:item.specie="{ item }">
      {{ $t(`species.${item.attributes.customAttributes.specie.text}`) }}
    </template>

    <template v-slot:item.dimensions="{ item }">
      <DimensionsTableField :item="item" />
    </template>
    <template v-slot:item.id="{ item }">
      <BaseTableActions
        v-if="isAdmin && actions"
        module-name="itemSalesQuotation"
        :item="item"
        :to-edit="{
          name: 'item-sales-quotation-edit',
          params: {
            itemSalesQuotationId: item.id,
            orderId
          }
        }"
      />
    </template>
  </v-data-table>
</template>

<script>
// import { ORGANIZATION_ID } from '@/common/config'
import { mapGetters } from 'vuex'
import DimensionsTableField from '@/components/DimensionsTableField'
import BaseTableActions from '@/components/buttons/BaseTableActions'
export default {
  components: {
    DimensionsTableField,
    BaseTableActions
  },
  props: {
    orderId: {
      type: String,
      required: true
    },
    actions: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      items: 'itemSalesQuotation/items'
    }),
    headers() {
      return this.createTableHeaders()
    }
  },
  methods: {
    deleteItem(item) {
      const answer = window.confirm(this.$t('alerts.delete'))
      if (answer) {
        this.$store.dispatch('itemSalesQuotation/delete', item)
      }
    },
    createTableHeaders() {
      return [
        {
          text: this.$t('product.description'),
          value: 'attributes.description',
          sortable: false
        },
        {
          text: this.$tc('product.part', 1) + '#',
          align: 'start',
          sortable: false,
          value: 'attributes.name'
        },
        {
          text: this.$t('product.requestedUnits'),
          value: 'attributes.pendingItemUnitsCount',
          sortable: false,
          width: 50
        },
        {
          text: this.$t('item.totalVolume'),
          align: 'start',
          value: 'totalVolume',
          sortable: false
        },
        {
          text: this.$t('item.dimensions'),
          value: 'dimensions',
          sortable: false
        },
        {
          text: this.$tc('user.supplier', 1),
          value: 'supplier',
          sortable: false
        },
        {
          text: this.$t('item.category'),
          value: 'attributes.customAttributes.category',
          sortable: false
        },
        {
          text: this.$t('item.type'),
          value: 'attributes.customAttributes.type',
          sortable: false,
          width: 100
        },
        {
          text: this.$t('item.ncCode'),
          value: 'attributes.customAttributes.ncCode',
          sortable: false
        },
        {
          text: this.$t('item.specie'),
          value: 'specie',
          sortable: false
        },
        {
          text: '',
          value: 'id',
          sortable: false,
          align: 'end'
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped></style>
