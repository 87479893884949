export const UnitsConversion = {
  methods: {
    toMeters(dimension, unit) {
      switch (unit) {
        case 'mm':
          dimension = this.roundNumber(dimension / 1000)
          break
        case 'cm':
          dimension = this.roundNumber(dimension / 100)
          break
        case 'in':
          dimension = this.roundNumber(dimension * 0.0254)
          break
        case 'ft':
          dimension = this.roundNumber(dimension * 0.3048)
          break
        default:
          break
      }
      return dimension
    },
    toCentimeters(dimension, unit) {
      switch (unit) {
        case 'm':
          dimension = this.roundNumber(dimension * 100)
          break
        case 'mm':
          dimension = this.roundNumber(dimension / 10)
          break
        default:
          break
      }
      return dimension
    },
    toMillimeters(dimension, unit) {
      switch (unit) {
        case 'm':
          dimension = this.roundNumber(dimension * 1000)
          break
        case 'cm':
          dimension = this.roundNumber(dimension * 10)
          break
        default:
          break
      }
      return dimension
    },
    toFeet(dimension, unit) {
      switch (unit) {
        case 'm':
          dimension = this.roundNumber(dimension / 0.3048)
          break
        default:
          break
      }
      return dimension
    },
    toInches(dimension, unit) {
      switch (unit) {
        case 'm':
          dimension = this.roundNumber(dimension / 0.0254)
          break
        default:
          break
      }
      return dimension
    },

    toCords(volume, unit) {
      switch (unit) {
        case 'm3':
          volume = this.roundNumber(volume / 3.62)
          break
        case 'ft':
          volume = this.roundNumber(volume / 128)
          break

        default:
          break
      }
      return volume
    },
    cilinderVolume(radius, length) {
      return Math.PI * radius * radius * length
    },
    rectangleVolume(length, width, height) {
      return length * width * height
    },
    boardFootVolume(length, width, height) {
      return (length * width * height) / 12
    },
    roundNumber(number, precision = 10000000) {
      return Math.round((number + Number.EPSILON) * precision) / precision
    }
  }
}
