<template>
  <base-secondary-table-layout :card-title="$tc('product.requestedPart', 2)">
    <template v-slot:button>
      <v-spacer></v-spacer>
      <!-- <SaleOrderConfirmationButton v-if="actions" /> -->

      <TaskGroupCreateButton v-if="isAdmin && actions" :order-id="orderId" />
      <base-new-outlined-button
        v-if="actions && isAdmin"
        :to="{
          name: 'item-sales-quotation-new',
          params: { orderId }
        }"
        :disabled="false"
        >{{ $tc('product.part', 1) }}</base-new-outlined-button
      >
    </template>
    <ItemSalesQuotationIndexTable :order-id="orderId" :actions="actions" />
  </base-secondary-table-layout>
</template>

<script>
import ItemSalesQuotationIndexTable from '@/views/item-sales-quotation/components/ItemSalesQuotationIndexTable'
// import SaleOrderConfirmationButton from '@/views/sale-order/components/SaleOrderConfirmationButton'
import TaskGroupCreateButton from '@/views/task-group/components/TaskGroupCreateButton'
// import { ORGANIZATION_ID } from '@/common/config'
import { mapGetters } from 'vuex'
export default {
  components: {
    ItemSalesQuotationIndexTable,
    // SaleOrderConfirmationButton,
    TaskGroupCreateButton
  },
  props: {
    orderId: {
      type: String,
      required: true
    },
    actions: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isLoading: true
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      itemSalesQuotation: 'itemSalesQuotation/item'
    })
  },
  async created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.isLoading = true
      await this.filterItemSalesQuotation()
      this.isLoading = false
    },
    async filterItemSalesQuotation() {
      return await this.$store.dispatch('itemSalesQuotation/filter', {
        // organizationId: ORGANIZATION_ID(),
        orderId: this.orderId
      })
    }
  }
}
</script>
