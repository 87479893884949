<template>
  <ul class="pl-0">
    <li v-if="item.attributes.dimensions.length">
      <span class="text-caption grey--text"> L</span>
      {{
        toMeters(
          item.attributes.dimensions.length,
          item.attributes.dimensionsUnit
        ) || '·'
      }}
      m
    </li>
    <li v-if="item.attributes.dimensions.width">
      <span class="text-caption grey--text"> W </span>
      {{
        toCentimeters(
          item.attributes.dimensions.width,
          item.attributes.dimensionsUnit
        ) || '·'
      }}
      cm
    </li>
    <li v-if="item.attributes.dimensions.height">
      <span class="text-caption grey--text"> T </span>
      {{
        toMillimeters(
          item.attributes.dimensions.height,
          item.attributes.dimensionsUnit
        ) || '·'
      }}
      mm
    </li>
    <li
      v-if="
        item.attributes.customAttributes && item.attributes.dimensions.diameter
      "
    >
      <span class="grey--text"> ⌀ </span>
      {{
        toCentimeters(
          item.attributes.customAttributes &&
            item.attributes.dimensions.diameter,
          item.attributes.dimensionsUnit
        ) || '·'
      }}
      cm
    </li>
    <li v-if="item.attributes.volume">
      <span class="grey--text"> Vol. </span>
      {{ item.attributes.volume || '-' }}
      {{
        item.attributes.volume && item.attributes.volumeUnit !== 'm³'
          ? item.attributes.volumeUnit
          : 'm³'
      }}
    </li>
  </ul>
</template>

<script>
import { UnitsConversion } from '@/mixins/units.conversion.mixin'
export default {
  mixins: [UnitsConversion],
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped></style>
