import { render, staticRenderFns } from "./ItemSalesQuotationIndexTable.vue?vue&type=template&id=a0d8755c&scoped=true&"
import script from "./ItemSalesQuotationIndexTable.vue?vue&type=script&lang=js&"
export * from "./ItemSalesQuotationIndexTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a0d8755c",
  null
  
)

export default component.exports