var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.attributes.name",fn:function({ item }){return [_c('div',{staticClass:"py-5"},[_vm._v(_vm._s(_vm._f("titleCase")(item.attributes.name)))])]}},{key:"item.status",fn:function({ item }){return [_c('v-chip',{attrs:{"small":"","light":"","text-color":"grey darken-2"}},[_vm._v(" "+_vm._s(_vm._f("titleCase")(item.attributes.status)))])]}},{key:"item.supplier",fn:function(){return [_vm._v(" Tonewoods S.L ")]},proxy:true},{key:"item.attributes.pendingItemUnitsCount",fn:function({ item }){return [_vm._v(" "+_vm._s(item.attributes.pendingItemUnitsCount)+" ("+_vm._s(item.attributes.customAttributes.packQuantity || '1')+") ")]}},{key:"item.totalVolume",fn:function({ item }){return [_vm._v(" "+_vm._s(item.attributes.volume * item.attributes.pendingItemUnitsCount * (item.attributes.customAttributes.packQuantity || 1))+" "+_vm._s(item.attributes.volume && item.attributes.volumeUnit)+" ")]}},{key:"item.specie",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.$t(`species.${item.attributes.customAttributes.specie.text}`))+" ")]}},{key:"item.dimensions",fn:function({ item }){return [_c('DimensionsTableField',{attrs:{"item":item}})]}},{key:"item.id",fn:function({ item }){return [(_vm.isAdmin && _vm.actions)?_c('BaseTableActions',{attrs:{"module-name":"itemSalesQuotation","item":item,"to-edit":{
        name: 'item-sales-quotation-edit',
        params: {
          itemSalesQuotationId: item.id,
          orderId: _vm.orderId
        }
      }}}):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }