<template>
  <v-btn depressed class="mr-2" :loading="isLoading" @click="onClick"
    ><v-icon left>mdi-briefcase-outline</v-icon>
    {{ taskGroups.length ? $t('actions.view') : $t('actions.create') }}
    {{ $tc('workOrder.name', 1) }}
  </v-btn>
</template>

<script>
export default {
  props: {
    orderId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      taskGroups: []
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.taskGroups = await this.filterTaskGroups
    },
    async onClick() {
      this.isLoading = true
      this.handleClick(this.taskGroups)
      this.isLoading = false
    },
    async filterTaskGroups() {
      return await this.$store.dispatch('taskGroup/filter', {
        outgoingShipping: this.orderId
      })
    },
    handleClick(taskGroups) {
      const name = taskGroups.length ? 'task-group-show' : 'task-group-new'
      const params = taskGroups.length
        ? { taskGroupId: taskGroups[0].id }
        : { orderId: this.orderId }
      this.$router.push({ name, params })
    }
  }
}
</script>

<style lang="scss" scoped></style>
