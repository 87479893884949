<template>
  <div id="top">
    <BaseLayoutTitle
      :prefix="$tc('workOrder.name', 1)"
      :title="taskGroup.attributes.title"
    >
      <template v-slot:edit>
        <v-btn
          :disabled="!isAdmin"
          :to="{ path: 'edit' }"
          append
          color="primary"
          outlined
          tile
          class="text-capitalize"
          ><v-icon left>mdi-clipboard-edit-outline</v-icon
          >{{ $t('actions.edit') }}</v-btn
        ></template
      >
    </BaseLayoutTitle>

    <BaseOverlayLoader :value="isLoading" />

    <v-row class="mt-1">
      <v-col cols="12" md="1">
        <v-progress-circular
          size="56"
          :value="taskGroup.attributes.progress"
          :color="taskGroup.attributes.progress === 100 ? 'success' : 'warning'"
        >
          <strong>{{ Math.ceil(taskGroup.attributes.progress) || 0 }}%</strong>
        </v-progress-circular></v-col
      >
      <v-col>
        <p class="text--disabled">
          {{ $tc('sale.name', 1) }}
          <span
            class="grey--text text--darken-2 font-weight-bold text-subtitle-1 mx-5"
          >
            {{ (order && order.attributes.customAttributes.name) || '-' }}
          </span>
          <v-chip v-if="order" small outlined pill ligth>
            {{ $t(`orderStatus.${order.attributes.status}`) }}
          </v-chip>
        </p>
        <p class="text--disabled">
          {{ $tc('purchase.purchaseOrder', 2) }}
          <span
            class="grey--text text--darken-2 font-weight-bold text-subtitle-1 ml-5"
          >
            {{
              (order && order.attributes.customAttributes.purchaseOrders) || '-'
            }}
          </span>
        </p>
      </v-col>

      <v-col>
        <div>
          <p class="text-subtitle-1 grey--text font-weight-bold">
            <v-icon left color="grey lighten-2">mdi-account-outline</v-icon
            >{{
              taskGroup.attributes.deliveredTo.companyName ||
                $t('common.notAvailable')
            }}
          </p>
          <p class="text--body-2 grey--text d-flex py-2">
            <v-icon left color="grey lighten-2">mdi-map-marker-outline</v-icon>
            {{
              taskGroup.attributes.deliveredTo.location.value ||
                $t('common.notAvailable')
            }}
          </p>
          <p class="text--body-2 grey--text d-flex py-2">
            <v-icon left color="grey lighten-2">mdi-calendar-range</v-icon>
            <span v-if="taskGroup.attributes.deliveredTo.estimatedUnloadDateTo"
              >{{
                taskGroup.attributes.deliveredTo.estimatedUnloadDateTo
                  | moment('ll')
              }}
              (ETD)</span
            >
            <span v-else>ETD {{ $t('common.notAvailable') }}</span>
          </p>
        </div>
      </v-col>
    </v-row>
    <!--  -->
    <!-- Items -->
    <!--  -->
    <ItemSalesQuotationIndex
      v-if="order && order.id"
      :order-id="order.id"
      :actions="false"
    />
    <!--  -->
    <!-- Subwork orders -->
    <!--  -->
    <v-card flat class="mt-5">
      <v-card-title class="text-h6"
        >{{ $tc('workOrder.subWorkOrder', 2) }} <v-spacer></v-spacer>
        <base-new-outlined-button
          light
          :disabled="!isAdmin"
          @click="editTask()"
          >{{ $tc('workOrder.subWorkOrder', 1) }}</base-new-outlined-button
        >
      </v-card-title>
      <router-view />
      <table>
        <thead v-if="!rowExpand" class="text-body-2 grey--text text--darken-1">
          <th>#{{ $tc('workOrder.subWorkOrder', 1) }}</th>
          <th></th>
          <th>{{ $tc('workOrder.worker', 1) }}</th>
          <th>{{ $t('common.status') }}</th>
          <th></th>
          <th></th>
        </thead>
        <tbody v-if="workTasks && workTasks.length === 0">
          <tr class="border-bottom border-top">
            <td colspan="6">{{ $t('workOrder.emptyMessage') }}</td>
          </tr>
        </tbody>
        <tbody v-if="workTasks && workTasks.length > 0">
          <template v-for="(task, i) in workTasks">
            <tr :key="i + 'row1'" class="border-top">
              <td class="text-subtitle-1">
                {{ task.attributes.title }}
              </td>

              <td>
                <v-icon small color="grey lighten-2">mdi-clock-outline</v-icon>
                {{ formatHours(task.attributes.estimatedWorkTime) }} hrs.
              </td>

              <td v-if="task.attributes.owners.length > 0">
                <v-chip
                  v-for="(name, j) in getUserNames(task.attributes.owners)"
                  :key="j"
                  class="mx-1"
                  color="light-blue lighten-5"
                  small
                  >{{ name }}</v-chip
                >
              </td>
              <td v-else>-</td>
              <td>
                <TaskIndexStatusButton
                  ref="taskIndexStatusButton"
                  :task="task"
                  @click="fetchTaskGroup"
                  @error="errorMessage($event, i)"
                  @toggle-row="expandRow(i)"
                />
              </td>

              <td>
                <v-btn
                  :disabled="task.attributes.status !== 'pending'"
                  small
                  color="primary"
                  class="text-capitalize"
                  text
                  :to="{
                    name: 'input-item-new',
                    params: { taskGroupId, taskId: task.id }
                  }"
                >
                  <v-icon left>mdi-plus</v-icon>{{ $t('workOrder.input') }}
                  {{
                    task.attributes.inputItems.length
                      ? `(${task.attributes.inputItems.length})`
                      : null
                  }}
                </v-btn>

                <v-btn
                  :disabled="
                    task.attributes.status === 'delivered' ||
                      !task.attributes.inputItems.length
                  "
                  small
                  color="primary"
                  class="text-capitalize"
                  text
                  :to="{
                    name: 'output-item-new',
                    params: { taskGroupId, taskId: task.id }
                  }"
                >
                  <v-icon left>mdi-plus</v-icon>{{ $t('workOrder.output') }}
                  {{
                    task.attributes.outputItems.length
                      ? `(${task.attributes.outputItems.length})`
                      : null
                  }}
                </v-btn>
              </td>

              <td>
                <v-icon
                  small
                  class="mx-1"
                  :disabled="task.attributes.status === 'delivered'"
                  color="warning"
                  @click="editTask(task.id)"
                  >mdi-pencil</v-icon
                >
                <v-icon
                  small
                  class="mx-1"
                  color="red lighten-2"
                  @click="deleteTask(task.id)"
                  >mdi-delete</v-icon
                >
                <!-- <v-btn color="red lighten-2" icon @click="deleteTask(task.id)"
                  ><v-icon small>mdi-delete</v-icon></v-btn
                > -->
                <v-icon
                  v-if="task.attributes.inputItems.length"
                  class="mx-1"
                  @click="expandRow(i)"
                  >{{
                    rowExpand === i ? 'mdi-menu-up' : 'mdi-menu-down'
                  }}</v-icon
                >
              </td>
            </tr>

            <tr v-for="(error, k) in errors" :key="i + k">
              <td v-if="task.id === error.id" :key="k" colspan="6">
                <!-- <template v-for="(error, k) in errors"> -->
                <v-alert :type="error.type" text dense>
                  {{ error.message }}</v-alert
                >
                <!-- </template> -->
              </td>
            </tr>
            <tr v-if="task.attributes.details" :key="i + 'row2'">
              <td
                v-if="task.attributes.details"
                colspan="6"
                class="text-body-2"
              >
                <template
                  v-for="(line, x) in task.attributes.details.split('\n')"
                >
                  {{ line }}<br :key="x + 'line'"
                /></template>
              </td>
              <td v-else colspan="6"></td>
              <td></td>
            </tr>
            <tr
              v-if="task.attributes.statusReason"
              :key="i + 'row3'"
              class="border-bottom"
            >
              <td colspan="6" class="py-0">
                <v-alert dense text type="info" icon="mdi-close">
                  {{ task.attributes.statusReason | sentenceCase }}</v-alert
                >
              </td>
            </tr>
            <tr
              v-if="rowExpand === i"
              :key="task.id + 'row2'"
              class="grey lighten-5"
            >
              <td colspan="8">
                <v-alert
                  v-if="task.attributes.status === 'started'"
                  type="warning"
                  text
                >
                  <v-row align="center">
                    <v-col class="grow">
                      {{ $t('alerts.deliverTaskMessage') }}
                    </v-col>
                    <v-col class="shrink">
                      <div class="d-flex">
                        <v-btn
                          depressed
                          small
                          color="success"
                          class="mx-1"
                          @click="
                            $refs.taskIndexStatusButton[i].changeStatus(
                              'delivered'
                            )
                          "
                          >{{ $t('actions.confirm') }}</v-btn
                        >
                      </div>
                    </v-col>
                  </v-row>
                </v-alert>
                <table>
                  <thead class="grey--text">
                    <th></th>
                    <th>{{ $tc('item.name', 1) }}</th>
                    <th>{{ $t('item.description') }}</th>
                    <th>{{ $tc('item.unit', 1) }}</th>
                    <th>{{ $t('facility.location') }}</th>
                    <th>{{ $t('item.dimensions') }}</th>
                    <th>{{ $t('item.volume') }}</th>
                    <!-- <th>{{ $t('common.remarks') }}</th> -->
                  </thead>

                  <!-- Input Items -->
                  <tbody v-if="rowExpand === i">
                    <tr
                      v-for="item in task.attributes.inputItems"
                      :key="item.data.id"
                      class="pointer"
                      @click="rowClicked(item.data)"
                    >
                      <td>
                        <v-icon color="amber accent-3">mdi-import</v-icon>
                      </td>
                      <td>
                        {{
                          item.data.attributes.name
                            ? $t(
                                `inventory.categories.${item.data.attributes.name}`
                              )
                            : '-'
                        }}
                      </td>
                      <td>
                        {{ item.data.attributes.description || '-' }}
                      </td>
                      <!-- <td>{{ item.data.attributes.category | titleCase }}</td> -->
                      <td>
                        {{ item.data.attributes.units }}
                      </td>
                      <td>
                        {{ getFacility(item.data.attributes.facilityId) }}
                      </td>
                      <td>
                        {{
                          item.data.attributes.dimensions.length &&
                            `L${item.data.attributes.dimensions.length}`
                        }}
                        {{
                          item.data.attributes.dimensions.width &&
                            `x W${item.data.attributes.dimensions.width}`
                        }}
                        {{
                          item.data.attributes.dimensions.height &&
                            `x T${item.data.attributes.dimensions.height}`
                        }}
                        {{
                          item.data.attributes.dimensions.diameter &&
                            `⌀${item.data.attributes.dimensions.diameter}`
                        }}
                        {{ item.data.attributes.dimensionsUnit }}
                      </td>
                      <td>
                        {{
                          item.data.attributes.units *
                            item.data.attributes.volume
                        }}
                      </td>
                      <td>
                        <v-icon
                          :disabled="task.attributes.status === 'delivered'"
                          class="mx-1"
                          color="warning"
                          small
                          @click.stop="
                            $router.push({
                              name: 'input-item-edit-quantity',
                              params: {
                                taskId: task.id,
                                inputItemId: item.data.id
                              }
                            })
                          "
                          >mdi-pencil-outline</v-icon
                        >
                        <!-- Error 500 when delete -->
                        <!-- <v-icon
                          :disabled="task.attributes.status !== 'pending'"
                          class="mx-1"
                          icon
                          color="red lighten-2"
                          small
                          @click.stop="
                            removeTaskItem(
                              'inputItem',
                              item.data,
                              task.id,
                              taskGroupId
                            )
                          "
                          >mdi-delete-outline</v-icon
                        > -->
                      </td>
                    </tr>
                  </tbody>

                  <!-- Output Items -->
                  <tbody v-if="rowExpand === i">
                    <tr
                      v-for="item in task.attributes.outputItems"
                      :key="item.data.id"
                      class="pointer"
                      @click="rowClicked(item.data)"
                    >
                      <td>
                        <v-icon color="green accent-3">mdi-export</v-icon>
                      </td>
                      <td>
                        {{
                          item.data.attributes.name
                            ? $t(
                                `inventory.categories.${item.data.attributes.name}`
                              )
                            : '-'
                        }}
                      </td>
                      <td>
                        {{ item.data.attributes.description || '-' }}
                      </td>
                      <td>
                        {{ item.data.attributes.units }}
                      </td>
                      <td>
                        {{ getFacility(item.data.attributes.facilityId) }}
                      </td>
                      <td>
                        <!-- {{ item.data.attributes.dimensions | formatDimension }} -->
                        {{
                          item.data.attributes.dimensions.length &&
                            `L${item.data.attributes.dimensions.length}`
                        }}
                        {{
                          item.data.attributes.dimensions.width &&
                            `x W${item.data.attributes.dimensions.width}`
                        }}
                        {{
                          item.data.attributes.dimensions.height &&
                            `x T${item.data.attributes.dimensions.height}`
                        }}
                        {{
                          item.data.attributes.dimensions.diameter &&
                            `⌀${item.data.attributes.dimensions.diameter}`
                        }}
                        {{ item.data.attributes.dimensionsUnit }}
                      </td>
                      <td>
                        {{
                          item.data.attributes.units *
                            item.data.attributes.volume
                        }}
                      </td>

                      <td>
                        <v-icon
                          :disabled="task.attributes.status === 'delivered'"
                          class="mx-1"
                          color="warning"
                          small
                          @click.stop="
                            $router.push({
                              name: 'output-item-edit-quantity',
                              params: {
                                taskId: task.id,
                                outputItemId: item.data.id
                              }
                            })
                          "
                          >mdi-pencil-outline</v-icon
                        >
                        <v-icon
                          :disabled="task.attributes.status === 'delivered'"
                          class="mx-1"
                          icon
                          color="red lighten-2"
                          small
                          @click.stop="
                            removeTaskItem(
                              'outputItem',
                              item.data,
                              task.id,
                              taskGroupId
                            )
                          "
                          >mdi-delete-outline</v-icon
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </v-card>
  </div>
</template>

<script>
import { TASK_GROUP } from '@/models/task.group'
import { ORGANIZATION_ID } from '@/common/config.js'
import { mapActions, mapGetters } from 'vuex'
import TaskIndexStatusButton from '@/views/task/components/TaskIndexStatusButton.vue'
import ItemSalesQuotationIndex from '@/views/item-sales-quotation/components/ItemSalesQuotationIndex'
import BaseOverlayLoader from '@/components/BaseOverlayLoader'

export default {
  components: {
    TaskIndexStatusButton,
    ItemSalesQuotationIndex,
    BaseOverlayLoader
  },
  props: {
    taskGroupId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      drawer: true,
      rowExpand: null,
      order: null,
      taskGroup: TASK_GROUP(),
      workTasks: [],
      users: [],
      facilities: [],
      items: [],
      filters: [],
      errors: [],
      isLoading: true
    }
  },
  computed: {
    ...mapGetters('auth', ['isAdmin'])
  },

  watch: {
    $route: {
      immediate: true,
      async handler() {
        this.isLoading = true
        await this.fetchTaskGroup(this.taskGroupId)
        this.isLoading = false
        const orderId = this.taskGroup.attributes.outgoingShipping
        this.users = await this.filterUsers()
        this.facilities = await this.filterFacilities()
        this.items = await this.filterItems()
        if (orderId) {
          this.order = await this.fetchSaleOrder(orderId)
        }
      }
    }
  },

  methods: {
    ...mapActions({
      deleteOutputItem: 'outputItem/delete',
      deleteInputItem: 'inputItem/delete'
    }),
    editTask(taskId = '') {
      const name = taskId ? 'task-edit' : 'task-new'
      this.$router.push(
        {
          name,
          params: { taskId },
          hash: '#top'
        },
        () =>
          this.$vuetify.goTo(1000, {
            duration: 500,
            offset: 0,
            easing: 'linear'
          })
      )
    },
    async deleteTask(taskId) {
      const answer = window.confirm(this.$t('alerts.delete'))
      if (answer) {
        const index = this.workTasks.map(task => task.id).indexOf(taskId)
        await this.$store.dispatch('task/delete', taskId)
        this.workTasks.splice(index, 1)
      }
    },

    async fetchTaskGroup(taskGroupId = this.taskGroupId) {
      const { data, included } = await this.$store.dispatch(
        'taskGroup/fetch',
        taskGroupId
      )
      this.taskGroup = data
      this.workTasks = included.reverse()
    },
    async removeTaskItem(itemType, item, taskId, taskGroupId) {
      const fn =
        itemType === 'inputItem' ? this.deleteInputItem : this.deleteOutputItem
      await fn({ item, taskId, taskGroupId })
      const index = this.workTasks.map(task => task.id).indexOf(taskId)
      const taskItemIndex = this.workTasks[index].attributes[`${itemType}s`]
        .map(i => i.data.id)
        .indexOf(item.id)
      this.workTasks[index].attributes.outputItems.splice(taskItemIndex, 1)

      // this.fetchTaskGroup(taskGroupId)
    },

    async fetchSaleOrder(orderId) {
      return await this.$store.dispatch('saleOrder/fetch', orderId)
    },
    async filterUsers() {
      return await this.$store.dispatch('user/filter', {
        roles: 'worker',
        organizationId: ORGANIZATION_ID()
      })
    },
    async filterFacilities() {
      return await this.$store.dispatch('facility/filter', {
        organizationId: ORGANIZATION_ID()
      })
    },
    async filterItems() {
      return await this.$store.dispatch('item/filter', {
        category: 'resource'
      })
    },

    getUserNames(userIds) {
      let userNames = []
      userIds.forEach(userId => {
        const user = this.users.find(user => user.id === userId)
        if (user) {
          const { name, firstName } = user.attributes.info
          userNames.push(name || firstName)
        }
      })
      return userNames
    },
    getFacility(facilityId) {
      if (!facilityId) return
      const response = this.facilities.find(
        facility => facility.id === facilityId
      )
      return response ? response.attributes.name : null
    },

    getItem(itemId) {
      if (!itemId) return 'pending'
      const response = this.items.find(item => item.id === itemId)
      return response?.attributes?.description
    },
    expandRow(i) {
      this.rowExpand = this.rowExpand !== i ? i : null
    },
    formatHours(value) {
      // return this.$moment.duration(value).days()
      return value
    },
    rowClicked(item) {
      this.$router.push({
        name: 'inventory-item-show',
        params: { inventoryItemId: item.attributes.itemId }
      })
    },
    errorMessage(error) {
      this.errors.push(error)
      setTimeout(() => {
        this.errors = []
      }, 5000)
    }
  }
}
</script>

<style lang="scss" scoped>
.list-padding li {
  padding: 4px 0px;
}
table {
  width: 100%;
  border-collapse: collapse;
}
th,
td {
  padding: 0.75rem 0.5rem;
  text-align: left;
}
th {
  height: 50px;
}

td {
  height: 60px;
  font-size: 0.9rem;
}
.border-top td {
  border-top: 1px solid rgb(238, 238, 238);
}
.border-bottom td {
  border-bottom: 1px solid rgb(238, 238, 238);
}
.pointer {
  cursor: pointer;
}
</style>
